import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollToTop from './Component/NavBarFooter/ScrollToTop';
import WeedingSet from './Pages/WeedingSet';
import RentelSpaces from './Pages/RentelSpaces';

const HomePage = React.lazy(() => import('./Pages/HomePage'));
const ProdectionServices = React.lazy(() => import('./Pages/ProdectionServices'));
const ContactUs = React.lazy(() => import('./Pages/ContactUs'));
const TermsCondition = React.lazy(() => import('./Pages/TermsCondition'));
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'));
const Faqs = React.lazy(() => import('./Pages/Faqs'));
const Props = React.lazy(() => import('./Pages/Props'));

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<div style={{height:"100vh"}} className='d-flex justify-content-center align-items-center'>
        <div className='main_login'>
        <div class="container">
  <div class="dash uno"></div>
  <div class="dash dos"></div>
  <div class="dash tres"></div>
  <div class="dash cuatro"></div>
</div>
        </div>
      </div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/production-services" element={<ProdectionServices />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term-and-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/wedding-set-for-rent" element={<WeedingSet />} />
          <Route path="/studio-spaces" element={<RentelSpaces />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/props" element={<Props />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
