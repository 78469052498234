import React from 'react'
import NavBar from '../Component/NavBarFooter/NavBar'
// import Banner from '../Component/Home/Banner'
import Listing from '../Component/RentelSpaces/Listing'
import Footer from '../Component/NavBarFooter/Footer'

function RentelSpaces() {
  return (
    <div>
        <NavBar/>
        {/* <Banner/> */}
        <Listing/>
        <Footer/>
        
    </div>
  )
}

export default RentelSpaces