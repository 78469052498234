import React from 'react';
import { Button, FallBackImage } from '../TagsUse/data';
import { studioData } from '../../Context/dummy';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

function StudioSection() {
  return (
    <div className='container my-5'>
      {studioData?.map((item, i) => (
        <div
          className={`row m-0 ${i % 2 !== 0 ? 'flex-md-row-reverse' : ''}`}
          key={i}
        >
          {/* Image Section */}
          <div className='col-lg-6 col-md-6 col-12 my-3'>
            <FallBackImage
              src={item?.imgae}
              alt="Studio Image"
              className="studio-image w-100"
              fallbackSrc={item?.imgae}
            />
          </div>

          {/* Content Section */}
          <div className='col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center my-3'>
            <div>
              <h6 className='studio_heding'>{item?.headging}</h6>
              <p style={{textTransform:"uppercase"}} className='sub_title_studio'>{item?.tittle}</p>
              <p>{item?.text1}</p>

              {/* Amenities List */}
              <ul className='p-0'>
                {item?.aminties.map((list, index) => (
                  <li
                    className='stu_amin d-flex align-items-start flex-wrap'
                    style={{ listStyle: 'none' }}
                    key={index}
                  >
                    <IoMdCheckmarkCircleOutline style={{ color: '#483B8B' }} className='mt-1'/>
                    <span style={{width:"90%"}} className='mx-1'>{list}</span>
                  </li>
                ))}
              </ul>

              <p>{item?.text2}</p>

              {/* Pricing Table */}
              <div className='d-flex justify-content-between'>
                <table className='table table-sm'>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#F2F2F2' }}>Price</th>
                      <th style={{ backgroundColor: '#F2F2F2' }}>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{item?.tow_hours_price}</td>
                      <td>2 hours</td>
                    </tr>
                    <tr>
                      <td>{item?.four_hours_price}</td>
                      <td>4 hours</td>
                    </tr>
                    {/* <tr>
                      <td>{item?.eight_hours_price}</td>
                      <td>8 hours</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              {/* Booking Button */}
              <a
                href={`https://wa.me/+923051115501`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button text="Book Now" className="booking_btn" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default StudioSection;
