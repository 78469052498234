import React from 'react'
import NavBar from '../Component/NavBarFooter/NavBar'
import Footer from '../Component/NavBarFooter/Footer'
// import Banner from '../Component/Home/Banner'
import StudioSection from '../Component/Home/StudioSection'

function WeedingSet() {
  return (
    <div>
        <NavBar/>
        <div className='container our_product my-5'>
        <div className='d-flex justify-content-between align-items-start flex-wrap px-3'>
            <h5 className='m-md-0 my-2'>Sets for wedding shoots</h5>
            {/* <input onChange={(e)=>handleSearch(e.target.value)} type="text" style={{background:"#f6f6f6",border:"none",borderRadius:"3px "}} placeholder="Search by Sets"/> */}
            </div>
        </div>
        {/* <Banner/> */}
        <StudioSection/>
        <Footer/>
    </div>
  )
}

export default WeedingSet